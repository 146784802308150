import { ExploreButton } from "../../lifestyle-savings/shared-styles"
import { Button, css, styled, Typography } from "@mui/material"
import { ContentGrid } from "../../portal-homepage/dynamic-propositions/health-and-wellbeing/Content"
import { styleVars } from "../../../style"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../utils/useBuildAwsImageUrl"
import {
  HEALTH_ICONS,
  YOUR_CARE_ICONS,
  LEVEL_5,
} from "../../portal-homepage/dynamic-propositions/health-and-wellbeing/GreenBoxContent"
export type MegaProps = {
  handleLink: (link: string, external: boolean) => void
  level?: string | null
  link?: string | null
}
const DesktopCard = ({ handleLink, level, link }: MegaProps) => {
  const { t, i18n, ready } = useTranslation("layout")
  const not_available = useBuildAwsImageUrl(i18n.language, "not_available.png")
  let images: string[] = []
  let icons: { name: string; img: string }[] = []

  if (level === "level_1" || level === "level_2") {
    images = HEALTH_ICONS.map((icon) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useBuildAwsImageUrl(i18n.language, icon.img)
    )
    icons = HEALTH_ICONS
  } else if (level === "level_3" || level === "level_4") {
    images = YOUR_CARE_ICONS.map((icon) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useBuildAwsImageUrl(i18n.language, icon.img)
    )
    icons = YOUR_CARE_ICONS
  } else if (level === "level_5") {
    images = LEVEL_5.map((icon) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useBuildAwsImageUrl(i18n.language, icon.img)
    )
    icons = LEVEL_5
  }

  if (!ready) {
    return null
  }
  return (
    <>
      {level === "disabled" || level === "hidden" ? (
        <div>
          <img
            src={not_available}
            alt="HealthAndWellbeing"
            className="mega-menu-tab____health-and-well-being-not-available-image"
          />
        </div>
      ) : null}

      {["level_1", "level_2"].includes(level || "") && (
        <div
          className="mega-menu-tab__health-and-wellbeing-image"
          style={{ textAlign: "right", marginTop: "2rem" }}
        >
          <ContentContainer>
            {icons.map((icon, index) => (
              <IconContainer
                sx={{ width: "6rem" }}
                href={link || "#"}
                component="a"
                target="_blank"
                rel="noopener"
              >
                <div style={{ height: "3.5rem" }} className="content-center">
                  <img src={images[index]} />
                </div>
                <div>{t(`menu.${icon.name}`)}</div>
              </IconContainer>
            ))}
          </ContentContainer>
          <HealthAndWellbeingButton
            onClick={() => link && handleLink(link, true)}
          >
            {t("menu.exploreHealthAndWellbeing")}
          </HealthAndWellbeingButton>
        </div>
      )}
      {(level == "level_3" || level == "level_4" || level == "level_5") && (
        <div
          className="mega-menu-tab__health-and-wellbeing-image"
          style={{ textAlign: "center" }}
        >
          <ContentContainer>
            {icons.map((icon, index) => (
              <IconContainer
                href={link || "#"}
                component="a"
                target="_blank"
                rel="noopener"
              >
                <div style={{ height: "3.5rem" }} className="content-center">
                  <img src={images[index]} />
                </div>
                <div>{t(`menu.${icon.name}`)}</div>
              </IconContainer>
            ))}
          </ContentContainer>
          <HealthAndWellbeingButton
            onClick={() => link && handleLink(link, true)}
          >
            {t("menu.exploreHealthAndWellbeing")}
          </HealthAndWellbeingButton>
        </div>
      )}
    </>
  )
}
export default DesktopCard
export const HealthAndWellbeingButton = styled(ExploreButton)`
  &&& {
    max-width: 100%;
    width: 316.61px;
    background-color: #488200;
    height: 3.125rem;
    @media screen and (min-width: ${styleVars.mdBreakpoint}) {
      font-size: 0.6rem;
      height: 2.125rem;
    }
    @media screen and (min-width: ${styleVars.lgBreakpoint}) {
      font-size: 1rem;
      height: 3.125rem;
    }
    &:hover {
      opacity: 75%;
      background-color: #488200;
    }
  }
`

export const IconContainer = styled(Button)`
  ${({ theme }) => css`
    display: flex;
    width: 8rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 0.75rem;
    padding: 0;
    line-height: 1.2;
    color: ${theme.palette.primary.main};
  `}
`

export const ContentContainer = styled(ContentGrid)`
  && {
    width: 100%;
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    justify-content: center;
  }
`
export const ContentContainerHeader = styled(Typography)`
  text-align: center;

  font-size: 15px;
  line-height: 22px;
  margin-bottom: 4px;
  @media screen and (min-width: ${styleVars.mdBreakpoint}) {
    font-size: 14 px;
  }

  @media screen and (min-width: ${styleVars.lgBreakpoint}) {
    font-size: 21px;
  }
`
export const TelContainer = styled(Typography)`
  font-size: 20px;

  font-weight: bold;
  color: #488200;
  margin: 8px auto;
  text-align: center;

  @media screen and (min-width: ${styleVars.mdBreakpoint}) {
    font-size: 25px;
    margin: 0px auto;
  }

  @media screen and (min-width: ${styleVars.lgBreakpoint}) {
    font-size: 37px;
    margin: 0px auto;
    line-height: 51px;
  }
`
export const FooterContainer = styled(Typography)`
  font-size: 8.7px;
  line-height: 12px;
  color: ${styleVars.primary};
  width: 62.9%;
  text-align: center;
  margin: 0 auto;

  @media screen and (min-width: ${styleVars.mdBreakpoint}) {
    font-size: 9.3px;
    line-height: 12px;
    margin-top: 10px;
    width: 87.9%;
  }

  @media screen and (min-width: ${styleVars.lgBreakpoint}) {
    font-size: 0.875rem;
    line-height: 16px;
    width: 273.81px;
    height: 33px;
  }
`
