import {
  Link,
  Typography,
  Box,
  Container,
  Grid,
  TextField,
  InputAdornment,
  styled,
  Button,
  css,
} from "@mui/material"
import { Search, Cancel, ArrowDropDown } from "@mui/icons-material"
import { useParams, useSearchParams } from "react-router-dom"
import Layout from "../../Layout"
import { useSchemeSuppliersData } from "../SchemSupplierContext"
import Maps from "../../Map/Map"
import { useEffect, useState } from "react"
import useGetSuppliersCall from "./useGetSuppliersCall"
import {
  gtmCycleToWorkCycleToWorkSearchForShop,
  gtmCycleToWorkStoreClick,
} from "../../../shared/gtm-events/CustomGtmEvents"
import InfoTooltip from "../../../shared/InfoTooltip"
import decathlonLogo from "../../../shared/assets/decathlonLogo.png"
import { useTranslation } from "react-i18next"

const FindABikeShop = () => {
  const { data } = useSchemeSuppliersData({})
  const { t, ready } = useTranslation("findBikeShop")
  const { organisationId, schemeType } = useParams()
  const bikeQuotePageUrl = `/organisations/${organisationId}/employee/benefits/${schemeType}/enter_bike_quote`
  const fullListOfBikeShopsUrl = `/organisations/${organisationId}/employee/benefits/${schemeType}/find_a_bike_shop/full_list_of_bike_shops`
  const [searchTerm, setSearchTerm] = useState("")
  const [showMoreResults, setShowMoreResults] = useState(false)
  const [, setSearchParams] = useSearchParams()
  const [typingTimeout, setTypingTimeout] = useState<number | null>(null)
  const params = useParams()
  const searchResults = useGetSuppliersCall(
    searchTerm.length >= 3 ? searchTerm : ""
  )

  useEffect(() => {
    if (searchTerm.length === 0) {
      setShowMoreResults(false)
    }
  }, [searchTerm])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)

    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }

    setTypingTimeout(
      window.setTimeout(() => {
        gtmCycleToWorkCycleToWorkSearchForShop(event.target.value)
      }, 500)
    )
  }

  const handleClearValue = () => {
    setSearchTerm("")
    setSearchParams({})
  }

  const handleResultClick = (
    supplierId: string,
    supplierName?: string | null
  ) => {
    setSearchParams({ supplier_id: supplierId })
    gtmCycleToWorkStoreClick(searchTerm, supplierName ?? "")
  }

  if (!ready) {
    return null
  }

  return (
    <Layout
      breadcrumbsItems={data.breadcrumbs}
      bannerTitle={t("findBikeShop.mainHeader")}
    >
      <Instruction variant="body1" data-cy="find-a-bike-shop-info">
        {t("findBikeShop.instructionText")}{" "}
        <TextWithLink href={bikeQuotePageUrl}>
          {t("findBikeShop.instructionLink")}
        </TextWithLink>{" "}
      </Instruction>
      <Container>
        <Grid container spacing={8}>
          <Grid item xs={12} md={4.5}>
            <SearchBox data-cy="find-a-bike-shop-searchbox">
              <h3>{t("findBikeShop.mainHeader")}</h3>
              {searchTerm === "" && (
                <label htmlFor="bike-shop-search">
                  <span className="d-none">
                    {t("findBikeShop.bikeShopSearch")}
                  </span>
                </label>
              )}
              <TextField
                id="bike-shop-search"
                placeholder={t("findBikeShop.bikeShopSearch")}
                label={
                  searchTerm !== "" ? t("findBikeShop.bikeShopSearch") : ""
                }
                value={searchTerm}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {searchTerm && (
                        <Cancel
                          fontSize="small"
                          sx={{ cursor: "pointer", color: "#656565" }}
                          onClick={() => handleClearValue()}
                        />
                      )}
                    </>
                  ),
                }}
                variant="outlined"
                sx={{
                  marginTop: "1.75rem",
                  marginBottom: "2.375rem",
                  marginX: { md: "auto", xs: "-0.625rem" },
                  width: { lg: "20.5rem", md: "16.5rem" },
                  input: {
                    color: "black",
                    "&::placeholder": {
                      opacity: 0.6,
                    },
                  },
                }}
              />
              {searchResults.suppliers.length > 5 ? (
                <>
                  {searchResults.suppliers.slice(0, 5).map((supplier) => (
                    <SearchResult
                      onClick={() =>
                        handleResultClick(supplier.id, supplier.name)
                      }
                    >
                      {supplier.name}
                      {supplier.bikeShopQuoteInformation &&
                        supplier.bikeShopQuoteInformation.length > 0 && (
                          <InfoTooltip
                            content={
                              t("findBikeShop.pleaseNote") +
                              supplier.bikeShopQuoteInformation
                            }
                          />
                        )}
                    </SearchResult>
                  ))}
                  {showMoreResults &&
                    searchResults.suppliers.slice(5).map((supplier) => (
                      <SearchResult
                        onClick={() => {
                          handleResultClick(supplier.id, supplier.name)
                        }}
                      >
                        {supplier.name}
                        {supplier.bikeShopQuoteInformation &&
                          supplier.bikeShopQuoteInformation.length > 0 && (
                            <InfoTooltip
                              content={
                                t("findBikeShop.pleaseNote") +
                                supplier.bikeShopQuoteInformation
                              }
                            />
                          )}
                      </SearchResult>
                    ))}
                  {!showMoreResults && (
                    <MoreResults onClick={() => setShowMoreResults(true)}>
                      <ArrowDropDown />
                      {t("findBikeShop.moreButton")}
                    </MoreResults>
                  )}
                </>
              ) : (
                <>
                  {searchResults.suppliers.map((supplier) => (
                    <SearchResult
                      onClick={() => {
                        handleResultClick(supplier.id, supplier.name)
                      }}
                    >
                      {supplier.name}
                      {supplier.bikeShopQuoteInformation &&
                        supplier.bikeShopQuoteInformation.length > 0 && (
                          <InfoTooltip
                            content={
                              t("findBikeShop.pleaseNote") +
                              supplier.bikeShopQuoteInformation
                            }
                          />
                        )}
                    </SearchResult>
                  ))}
                </>
              )}
              <div style={{ marginBottom: "2rem" }}>
                <div>
                  <DecathlonLogo src={decathlonLogo} alt={"DecathlonLogo"} />
                </div>
                <div style={{ fontWeight: "bold", color: "#3643BA" }}>
                  Looking for Decathlon?
                </div>
                <div style={{ color: "#3643BA" }}>
                  Discover our wide range of Decathlon products{" "}
                  <strong>
                    <Link
                      href={`/organisations/${params.organisationId}/employee/benefits/${params.schemeType}/departments/${data.bikeDepartmentId}`}
                      component={"a"}
                      rel="noopener"
                      style={{
                        color: "#3643BA",
                        textDecorationColor: "3643BA !important",
                      }}
                    >
                      here
                      <span className="screen-reader-only">
                        (opens in a new tab)
                      </span>
                    </Link>
                  </strong>
                </div>
              </div>
              <Paragraph className="bold">
                {t("findBikeShop.lookingFor")}
              </Paragraph>
              <Paragraph>
                {t("findBikeShop.lookAtFullListFirstText")}{" "}
                <Link href={fullListOfBikeShopsUrl}>
                  {t("findBikeShop.lookAtFullListSecondText")}
                </Link>{" "}
                {t("findBikeShop.lookAtFullListThirdText")}
              </Paragraph>
            </SearchBox>
          </Grid>
          <Grid item xs={12} md={7.5}>
            <Maps BikeShops={data.mapSuppliers} searchTerm={searchTerm} />
          </Grid>
        </Grid>
      </Container>
      <br />
      <br />
    </Layout>
  )
}

export default FindABikeShop

const Instruction = styled(Typography)`
  ${({ theme }) => css`
    width: 82.1%;
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin: 0 auto;
    margin-top: 37px;
    margin-bottom: 1rem;
    text-align: center;
    color: ${theme.palette.primary.main};
    position: relative; // so padding-top from map doesn't cover the text

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 1rem;
      line-height: 1.25rem;
      max-width: 1254px;
      margin-top: 47px;
      margin-bottom: 52px;
    }
  `}
`

const DecathlonLogo = styled("img")`
  max-width: 170px;
  width: 100%;
  height: 34px;
`
const Paragraph = styled("p")`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    margin: 0 2rem;
  `}
`

const SearchBox = styled(Box)`
  ${({ theme }) => css`
    padding: 1.8rem 1.625rem 1.5rem 1.625rem;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: none;
    border-radius: ${theme.boxBorderRadius};
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      box-shadow: ${theme.boxShadow};
    }
  `}
`

const TextWithLink = styled(Link)`
  display: inline-block;
  color: #92237f;
`

const SearchResult = styled(Button)`
  ${({ theme }) => css`
    text-decoration: underline;
    color: ${theme.palette.primary.main};
    padding-bottom: 1rem;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  `}
`

const MoreResults = styled(Button)`
  color: #92237f;
  padding-top: 0.25rem;
`
