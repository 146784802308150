import React, { useEffect, useRef, useState } from "react"
import { styled, Typography, css } from "@mui/material"
import { Box } from "@mui/system"
import NoticeBoardChip from "./NoticeBoardChip"
import Slider from "react-slick"
import { Noticeboard as NoticeboardType } from "../../../graphqGenaretedTypes"
import { carouselDotStyling } from "../shared/SharedStyles"
import theme from "../../../theme/mui-theme"
import disableCarouselAriaHidden from "../../../utils/disableCarouselAriaHidden"
import { useTranslation } from "react-i18next"

const maxNumberOfChipsToShow = 4
const carouselId = "noticeboard"

const NoticeBoard: React.FC<
  React.PropsWithChildren<{ input: NoticeboardType[] }>
> = ({ input }) => {
  const inputLength = input.length

  const [, setSlideIndex] = useState<null | number>(inputLength > 0 ? 0 : null)

  const sliderRef = useRef<Slider | null>(null)

  // disable aria-hidden when mount, subsequent changes are handled afterChange()
  useEffect(() => {
    disableCarouselAriaHidden(carouselId)
  }, [])

  const settings = {
    accessibility: false,
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToScroll: 4,
    afterChange: (index: number) => {
      setSlideIndex(index)
      disableCarouselAriaHidden(carouselId)
    },
    rows: 1,
    slidesPerRow: 1,
    slidesToShow:
      inputLength > maxNumberOfChipsToShow
        ? maxNumberOfChipsToShow
        : inputLength,
    responsive: [
      {
        // signature says breakpoint is of Number type
        // but when transform the string to Number it doesn't render correctly?
        // use TS-ignore on Slider component
        breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesPerRow: inputLength <= 2 ? 1 : maxNumberOfChipsToShow / 2, // one row only if less than 3 tiles
          slidesToShow: maxNumberOfChipsToShow / 2, // 2
          slidesToScroll: 2,
        },
      },
    ],
  }

  const { t, ready } = useTranslation("layout")

  if (!ready) {
    return null
  }

  return (
    <Rectangle id={carouselId} data-cy="portal-noticeboard">
      <Container inputLength={inputLength}>
        <Heading variant="h3" align="left">
          {t("header.noticeboard")}
        </Heading>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment  */}
        {/* @ts-ignore */}
        <Slider {...settings} ref={sliderRef}>
          {inputLength > 0 &&
            input.map(({ label, link }: { label: string; link: string }) => (
              <NoticeBoardChip
                key={label}
                title={label}
                href={link}
                setSlideIndex={setSlideIndex}
                sliderRef={sliderRef}
              />
            ))}
        </Slider>
      </Container>
    </Rectangle>
  )
}

export default NoticeBoard

const Rectangle = styled(Box)`
  width: 100%;
  display: grid;
  place-items: center;
  margin-top: -6px; // to close the line in the background until there's a new SVG
`

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "inputLength",
})<{ inputLength: number }>(
  ({ theme, inputLength }) => css`
    /* mobile container */
    width: 86.4vw; // if %, container expands to infinity otherwise

    .slick-slide > div {
      /* column gap in mobile view */
      /* need to specify here instead of NoticeBoardChips.tsx */
      margin-right: 1rem;
    }

    .slick-slide > div > a:first-of-type {
      /* row gap in mobile view  */
      /* need to specify here instead of NoticeBoardChips.tsx */
      margin-bottom: 1rem;
      &:hover,
      &:focus {
        opacity: 0.6;
      }
    }

    .slick-list {
      /* gap between chips and dots */
      padding-bottom: 1rem;
      /* following paddings for the case when chips are hovered or focused, so the outline is not cut off */
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      padding-top: 0.1rem;
    }

    ${carouselDotStyling(theme)};

    .slick-dots > li {
      width: 1.5rem;
      height: 1.5rem;
    }

    .slick-dots > li:first-of-type {
      /* override from carouselDotStyling */
      margin-left: -0.5rem;
    }

    .slick-track {
      ${inputLength <= 2 &&
      `
      margin-left: 0;
      margin-right: 0;
    `}
    }

    .slick-arrow .slick-next {
      color: red;
    }

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 1102px; // 1104px makes more sense, but tighten a little to hide the left edge of 2nd page

      .slick-slide {
        // the div container for each chip
        // needs to set a max-width here to constrain the width of each chip
        max-width: calc(260px + 1rem);
      }

      .slick-slide > div > a:first-of-type {
        /* undo the mobile margin */
        margin-bottom: unset;
      }
    }
  `
)

const Heading = styled(Typography)`
  ${({ theme }) => css`
    // container 167 - chips container 114 - heading 29 = 24px
    margin-bottom: 1.5rem;
    font-weight: bold;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin-bottom: 2.5rem;
    }
  `}
`
