import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos"
import { styled, Typography } from "@mui/material"
import { styleVars } from "../../../style"
import {
  HealthAndWellbeingButton,
  ContentContainer,
  IconContainer,
} from "../global-mega-menu/HealthAndWellbeingDesktopCard"
import { gtmPortalHomepageHealthAndWellbeingLinkClick } from "../gtm-events/CustomGtmEvents"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../utils/useBuildAwsImageUrl"
import {
  HEALTH_ICONS,
  YOUR_CARE_ICONS,
  LEVEL_5,
} from "../../portal-homepage/dynamic-propositions/health-and-wellbeing/GreenBoxContent"

export type SlideProps = {
  HealthAndWellBeingLevel?: string | null
  customLink?: string | null
  handleLink: (customLink: string, external: boolean) => void
}
export type SlideCardProps = {
  HealthAndWellBeingLevel?: string | null
  customLink?: string | null
  handleLink: (customLink: string, external: boolean) => void
  eapNumber?: string | null
}
const PlatformNameHeading = ({
  HealthAndWellBeingLevel,
  customLink,
  handleLink,
}: SlideProps) => {
  const { t, ready } = useTranslation("layout")
  if (!ready) {
    return null
  }

  return (
    <div>
      {!(
        HealthAndWellBeingLevel === "disabled" ||
        HealthAndWellBeingLevel === "hidden"
      ) ? (
        <TittleLink
          className="mega-menu-tab__category mega-menu-tab__title"
          style={{
            paddingLeft: "0px",
            fontWeight: "bold",
          }}
          onClick={(event) => {
            const labelText = event.currentTarget.textContent
            const clickLocation = "Header Mega Menu"

            handleLink(customLink, true)
            gtmPortalHomepageHealthAndWellbeingLinkClick(
              labelText,
              customLink,
              clickLocation
            )
          }}
        >
          <div
            style={{
              fontSize: "14px",
              color: "#302557",
              fontWeight: "bold",
            }}
          >
            {["level_1", "level_2", "level_5"].includes(
              HealthAndWellBeingLevel || ""
            ) && t("menu.exploreHealthAndWellbeing")}
            {(HealthAndWellBeingLevel === "level_3" ||
              HealthAndWellBeingLevel === "level_4") &&
              t("menu.yourCareWellbeingPlatform")}
          </div>
          <ArrowForwardIcon
            fontSize="inherit"
            className="mega-menu-tab__forward-arrow"
            sx={{
              color: "#35185f",
            }}
          />
        </TittleLink>
      ) : null}
    </div>
  )
}
const MobileCard = ({
  customLink,
  handleLink,
  HealthAndWellBeingLevel,
}: SlideCardProps) => {
  const { t, i18n, ready } = useTranslation("layout")
  const mobile_img_not_available = useBuildAwsImageUrl(
    i18n.language,
    "mobile_img_not_available.png"
  )
  let images: string[] = []
  let icons: { name: string; img: string }[] = []

  if (
    HealthAndWellBeingLevel === "level_1" ||
    HealthAndWellBeingLevel === "level_2"
  ) {
    images = HEALTH_ICONS.map((icon) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useBuildAwsImageUrl(i18n.language, icon.img)
    )
    icons = HEALTH_ICONS
  } else if (
    HealthAndWellBeingLevel === "level_3" ||
    HealthAndWellBeingLevel === "level_4"
  ) {
    images = YOUR_CARE_ICONS.map((icon) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useBuildAwsImageUrl(i18n.language, icon.img)
    )
    icons = YOUR_CARE_ICONS
  } else if (HealthAndWellBeingLevel === "level_5") {
    images = LEVEL_5.map((icon) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useBuildAwsImageUrl(i18n.language, icon.img)
    )
    icons = LEVEL_5
  }

  if (!ready) {
    return null
  }
  return (
    <div>
      {HealthAndWellBeingLevel === "hidden" ||
      HealthAndWellBeingLevel === "disabled" ? (
        <>
          <NotAvailableImage
            src={mobile_img_not_available}
            alt="HealthAndWellbeing"
          />
        </>
      ) : null}
      {HealthAndWellBeingLevel === "level_1" ||
      HealthAndWellBeingLevel === "level_2" ? (
        <div style={{ textAlign: "center", marginTop: "28px" }}>
          <ContentContainer style={{ textAlign: "center" }}>
            {icons.map((icon, index) => (
              <IconContainer
                sx={{ width: "6rem" }}
                href={customLink || "#"}
                component="a"
                target="_blank"
                rel="noopener"
              >
                <div style={{ height: "3.5rem" }} className="content-center">
                  <img src={images[index]} />
                </div>
                <div>{t(`menu.${icon.name}`)}</div>
              </IconContainer>
            ))}
          </ContentContainer>
          <ExploreButton onClick={() => handleLink(customLink, true)}>
            {t("menu.exploreHealthAndWellbeing")}
          </ExploreButton>
        </div>
      ) : null}
      {HealthAndWellBeingLevel === "level_3" ||
      HealthAndWellBeingLevel === "level_4" ||
      HealthAndWellBeingLevel === "level_5" ? (
        <div style={{ textAlign: "center" }}>
          <ContentContainer style={{ textAlign: "center" }}>
            {icons.map((icon, index) => (
              <IconContainer
                href={customLink || "#"}
                component="a"
                target="_blank"
                rel="noopener"
              >
                <div style={{ height: "3.5rem" }} className="content-center">
                  <img src={images[index]} />
                </div>
                <div>{t(`menu.${icon.name}`)}</div>
              </IconContainer>
            ))}
          </ContentContainer>
          <ExploreButton onClick={() => handleLink(customLink, true)}>
            {t("menu.exploreHealthAndWellbeing")}
          </ExploreButton>
        </div>
      ) : null}
    </div>
  )
}
export { PlatformNameHeading, MobileCard }
const TittleLink = styled("div")`
  &:hover {
    background-color: transparent;
  }
`
const NotAvailableImage = styled("img")`
  display: block;
  margin: 2rem auto;
  width: 69.9%;
  max-width: 300px;
`
export const ContentContainerHeader = styled(Typography)`
  text-align: center;

  font-size: 15px;
  line-height: 40px;
  @media screen and (max-width: 372px) {
    font-size: 15px;
  }
`
export const TelContainer = styled(Typography)`
  && {
    font-size: 38px;
    margin-bottom: 1rem;
    font-weight: bold;
    color: #488200;
    margin: 8px auto;
    text-align: center;
    @media screen and (max-width: 372px) {
      font-size: 29px;
      margin: 0px auto;
    }
  }
`
export const FooterContainer = styled(Typography)`
  font-size: 9.2px;
  line-height: 13px;
  color: ${styleVars.primary};
  width: 62.9%;
  text-align: center;
  margin: 0 auto;
  padding-top: 0.7rem;
  @media screen and (max-width: 372px) {
    font-size: 7.2px;
    padding-top: 0.5rem;
    line-height: 9px;
  }
`
const ExploreButton = styled(HealthAndWellbeingButton)`
  && {
    max-width: 283.15px;
    width: 100%;
  }
`
